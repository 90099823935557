<template>
  <section class="profile--wrapper social--media-wrapper">
    <div class="title--text">
      {{ $t('profile.socialMedia') }}
    </div>
    <div class="input--wrapper">
      <social-media-field
        v-for="(socmed, index) in socmedList"
        :key="`socmed-${index}`"
        :field="socmed"
        :initValue="getValue(socmed, selectedSocmed)"
        :ref="socmed.socmed"
      ></social-media-field>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import SocialMediaField from '@/components/profile/partials/social-media-field';

export default {
  components: {
    SocialMediaField,
  },
  computed: {
    ...mapState({
      user: (state) => state.global.user,
      agentType: (state) => state.profile.agentType,
      socmedList: (state) => state.profile.socmedList,
      selectedSocmed: (state) => state.profile.selectedSocmed,
    }),
  },
  methods: {
    getValue(socmed, selectedSocmed) {
      let foundSocmed = selectedSocmed.find((e) => e.name === socmed.socmed);
      if (foundSocmed) {
        return foundSocmed.url;
      } else {
        return null;
      }
    },
    validate() {
      let allValues = [];
      for (let e of this.socmedList) {
        let field = this.$refs[e.socmed][0];
        if (field.value) {
          allValues.push({
            name: field.key,
            url: field.value,
          });
        }
      }
      this.$store.commit('profile/SET_SELECTED_SOCMED', allValues);

      let validationArray = this.socmedList.map((e) => this.$refs[e.socmed][0].validate());
      return Promise.all(validationArray).then(function (results) {
        if (
          results.filter(function (result) {
            return !result;
          }).length === 0
        ) {
          return true;
        }
        return false;
      });
    },
  },
};
</script>

<style lang="scss">
@import '../../../assets/scss/utils/variables';
.social--media-wrapper {
  margin-top: 0;
  margin-bottom: 36px;
  @media #{$phones} {
    margin-bottom: 16px;
  }
  .input--wrapper {
    padding: 20px 28px 20px 55px;
    @media #{$phones} {
      padding: 8px 0;
    }
  }
}
</style>
