<template>
  <section class="profile--wrapper">
    <section-loader :show="loading" />
    <div class="title--text">
      {{ $t('home.sidebarMenu.changeProfile') }}
    </div>
    <change-avatar />
    <v-card class="membership--card">
      <profile-membership></profile-membership>
    </v-card>
    <form @submit.prevent="updateProfile">
      <div class="input--wrapper">
        <div class="form--group" :class="{ 'has-error': validation.hasError('name') }">
          <div class="left--col">
            <label for="name">
              <span>{{ $t('profile.name') }}</span>
              <span class="required">*</span>
            </label>
          </div>
          <div class="right--col">
            <input readonly id="name" v-model="name" placeholder="Name" class="basic--input" />
            <span class="val-error">{{ validation.firstError('name') }}</span>
          </div>
        </div>
        <div class="form--group">
          <div class="left--col">
            <label for="email">
              <span>{{ $t('loginRegister.email') }}</span>
              <span class="required">*</span>
            </label>
          </div>
          <div class="right--col">
            <input readonly id="email" v-model="email" placeholder="Nama" class="basic--input" />
          </div>
        </div>
        <div class="form--group" :class="{ 'has-error': validation.hasError('agentType') }">
          <div class="left--col">
            <label for="agentType">
              <span>{{ $t('profile.agentType') }}</span>
              <span class="required">*</span>
            </label>
          </div>
          <div class="right--col">
            <multiselect
              name="agentType"
              id="agentType"
              v-model="agentType"
              :options="agentTypeList"
              :searchable="true"
              :close-on-select="true"
              track-by="id"
              label="name"
              select-label=""
              deselect-label=""
              disabled
            ></multiselect>
            <span class="val-error">{{ validation.firstError('agentType') }}</span>
          </div>
        </div>
        <div class="form--group">
          <div class="left--col">
            <label for="nib">
              <span>{{ $t('loginRegister.nib') }}</span>
            </label>
          </div>
          <div class="right--col">
            <input readonly id="nib" v-model="nib" placeholder="NIB" class="basic--input" />
          </div>
        </div>
        <div
          class="form--group"
          :class="{ 'has-error': validation.hasError('agentOffice') }"
          v-if="agentType != null && agentType.id === 2"
        >
          <div class="left--col">
            <label for="agentOffice">
              <span>{{ $t('profile.agentOffice') }}</span>
              <span class="required">*</span>
            </label>
          </div>
          <div class="right--col">
            <multiselect
              name="agentOffice"
              id="agentOffice"
              v-model="agentOffice"
              :options="agentOfficeList"
              :searchable="true"
              :close-on-select="true"
              track-by="id"
              label="name"
              select-label=""
              deselect-label=""
              disabled
            >
              <template slot="option" slot-scope="{ option }">
                <div class="agent--ofice-option">
                  <div class="option--img" v-if="option.logo_url">
                    <img :src="option.logo_url" :alt="option.name" />
                  </div>
                  <div class="option--text">
                    {{ option.name }}
                  </div>
                </div>
              </template>
              <template slot="singleLabel" slot-scope="{ option }">
                <div class="agent--ofice-option">
                  <div class="option--img" v-if="option.logo_url">
                    <img :src="option.logo_url" :alt="option.name" />
                  </div>
                  <div class="option--text">
                    {{ option.name }}
                  </div>
                </div>
              </template>
            </multiselect>
            <span class="val-error">{{ validation.firstError('agentOffice') }}</span>
          </div>
        </div>
        <my-alert variant="warning" :show="true">
          <div style="text-align: center">{{ $t('profile.contactUs') }}</div>
          <button
            type="button"
            class="btn btn--whatsapp"
            style="margin-left: auto; margin-right: auto; margin-top: 8px"
            @click="handleContact"
          >
            <img
              src="@/assets/img/icons/whatsapp-1.svg"
              alt="Phone"
              style="margin-right: 4px; width: 24px"
            />
            <span style="font-family: Poppins-SemiBold">{{ $t('general.contactUs') }}</span>
          </button>
        </my-alert>

        <div class="form--group">
          <div class="left--col">
            <label for="phone">
              <span>{{ $t('profile.phone') }}</span>
              <span class="required">*</span>
            </label>
          </div>
          <div class="right--col" style="position: relative">
            <client-only>
              <vue-tel-input
                id="phone"
                :value="phone ? phone : ''"
                :validCharactersOnly="true"
                defaultCountry="id"
                :onlyCountries="['id']"
                :inputOptions="inputOptions"
              />
            </client-only>
            <div
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                cursor: pointer;
              "
              @click="showVerifyPhone()"
            ></div>
          </div>
        </div>
        <div class="form--group" :class="{ 'has-error': validation.hasError('selectedAreaSpec') }">
          <div class="left--col">
            <label for="areaSpec">
              <span>{{ $t('profile.areaSpec') }}</span>
              <span class="required">*</span>
            </label>
          </div>
          <div class="right--col">
            <multiselect
              name="areaSpec"
              id="areaSpec"
              v-model="selectedAreaSpec"
              :options="areaSpecList"
              :searchable="true"
              :close-on-select="true"
              track-by="id"
              label="name"
              :multiple="true"
              select-label=""
              deselect-label=""
            ></multiselect>
            <span class="val-error">{{ validation.firstError('selectedAreaSpec') }}</span>
          </div>
        </div>
        <div
          class="form--group"
          :class="{ 'has-error': validation.hasError('selectedPropertySpec') }"
        >
          <div class="left--col">
            <label for="propertySpec">
              <span>{{ $t('profile.propertySpec') }}</span>
            </label>
          </div>
          <div class="right--col">
            <multiselect
              name="propertySpec"
              id="propertySpec"
              v-model="selectedPropertySpec"
              :options="propertySpecList"
              :searchable="true"
              :close-on-select="true"
              track-by="id"
              label="name"
              :multiple="true"
              select-label=""
              deselect-label=""
            ></multiselect>
            <span class="val-error">{{ validation.firstError('selectedPropertySpec') }}</span>
          </div>
        </div>
        <div class="form--group" :class="{ 'has-error': validation.hasError('selectedPriceSpec') }">
          <div class="left--col">
            <label for="priceSpec">
              <span>{{ $t('profile.priceSpec') }}</span>
            </label>
          </div>
          <div class="right--col">
            <multiselect
              name="priceSpec"
              id="priceSpec"
              v-model="selectedPriceSpec"
              :options="priceSpecList"
              :searchable="true"
              :close-on-select="true"
              track-by="id"
              label="name"
              :multiple="true"
              select-label=""
              deselect-label=""
            ></multiselect>
            <span class="val-error">{{ validation.firstError('selectedPriceSpec') }}</span>
          </div>
        </div>
      </div>
      <social-media ref="socialMediaField"></social-media>

      <div class="main--btn">
        <button class="btn btn-primary" type="submit">
          {{ $t('profile.saveBtn') }}
        </button>
      </div>
    </form>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import ChangeAvatar from '@/components/profile/change-avatar';
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';
// import FormTransition from '@/components/utils/form-transition';

import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
const SectionLoader = () => import('@/components/content-loading/section-loading');
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');
import ProfileMembership from '@/components/utils/profile-membership';
import SocialMedia from '@/components/profile/partials/social-media';
const MyAlert = () => import('@/components/utils/my-alert.vue');

export default {
  components: {
    VueTelInput,
    ChangeAvatar,
    // FormTransition,
    SectionLoader,
    Multiselect,
    ProfileMembership,
    SocialMedia,
    MyAlert,
  },
  mixins: [HelperMixin],
  data() {
    return {
      isAgent: false,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.global.user,
      agentTypeList: (state) => state.profile.agentTypeList,
      propertySpecList: (state) => state.profile.propertySpecList,
      priceSpecList: (state) => state.profile.priceSpecList,
      areaSpecList: (state) => state.profile.areaSpecList,
      agentOfficeList: (state) => state.profile.agentOfficeList,
      selectedSocmed: (state) => state.profile.selectedSocmed,
      allowEditPhoneNumberAfter: (state) => state.profile.allowEditPhoneNumberAfter,
    }),
    inputOptions() {
      return {
        placeholder: this.$t('profile.placeholder.phone'),
      };
    },
    name: {
      get() {
        return this.$store.state.global.user ? this.$store.state.global.user.name : null;
      },
      set(val) {
        let name = this.cleanInput(val);
        this.$store.commit('global/set_user_input', { name });
      },
    },
    email: {
      get() {
        return this.$store.state.global.user ? this.$store.state.global.user.email : null;
      },
      set(val) {
        let email = this.cleanInput(val);
        this.$store.commit('global/set_user_input', { email });
      },
    },
    phone: {
      get() {
        let user = this.$store.state.global.user;
        return user ? user.phone_number : '';
      },
      set(phone) {
        this.$store.commit('global/set_user_input', { phone });
      },
    },
    selectedPropertySpec: {
      get() {
        return this.$store.state.profile.selectedPropertySpec;
      },
      set(value) {
        this.$store.commit('profile/SET_SELECTED_PROPERTY_SPEC', value);
      },
    },
    selectedPriceSpec: {
      get() {
        return this.$store.state.profile.selectedPriceSpec;
      },
      set(value) {
        this.$store.commit('profile/SET_SELECTED_PRICE_SPEC', value);
      },
    },
    selectedAreaSpec: {
      get() {
        return this.$store.state.profile.selectedAreaSpec;
      },
      set(value) {
        this.$store.commit('profile/SET_SELECTED_AREA_SPEC', value);
      },
    },
    agentOffice: {
      get() {
        return this.$store.state.profile.agentOffice;
      },
      set(value) {
        this.$store.commit('profile/SET_AGENT_OFFICE', value);
      },
    },
    agentType: {
      get() {
        return this.$store.state.profile.agentType;
      },
      set(value) {
        this.$store.commit('profile/SET_AGENT_TYPE', value);
      },
    },
    nib: {
      get() {
        return this.$store.state.profile.nib;
      },
      set(value) {
        this.$store.commit('profile/SET_NIB', this.cleanInput(value));
      },
    },
  },
  validators: {
    agentType: {
      validator(value) {
        return Validator.value(value).required(this.$i18n.t('errors.agentType.required'));
      },
    },
    name: {
      validator(value) {
        return Validator.value(value).required(this.$i18n.t('errors.name.required'));
      },
    },
    selectedAreaSpec: {
      validator(value) {
        return Validator.value(value).required(this.$i18n.t('errors.selectedAreaSpec.required'));
      },
    },
  },
  async mounted() {
    this.isAgent = this.agentRegNo !== null && this.agentRegNo !== undefined;
    await this.$store.dispatch('profile/getProfileForm');
  },
  methods: {
    validate() {
      let validationArray = [this.$validate(), this.$refs.socialMediaField.validate()];
      return Promise.all(validationArray).then(function (results) {
        if (
          results.filter(function (result) {
            return !result;
          }).length === 0
        ) {
          return true;
        }
        return false;
      });
    },
    async updateProfile() {
      const isValid = await this.validate();
      if (isValid) {
        try {
          this.loading = true;
          let requestData = {
            agent_type_id: this.agentType ? this.agentType.id : null,
            agent_office_id:
              this.agentType && this.agentType.id === 2 && this.agentOffice
                ? this.agentOffice.id
                : null,
            property_specialization_ids: this.selectedPropertySpec.map((e) => e.id),
            price_specialization_ids: this.selectedPriceSpec.map((e) => e.id),
            area_specialization_ids: this.selectedAreaSpec.map((e) => e.id),
            social_medias: this.selectedSocmed,
          };
          let data = await this.$store.dispatch('profile/updateProfile', requestData);
          if (data) {
            this.$swal('Success', this.$t('profile.updateSuccess'), 'success');
            await this.$store.dispatch('global/fetch');
          }
          // eslint-disable-next-line no-empty
        } catch (e) {
        } finally {
          this.loading = false;
        }
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
    showVerifyPhone() {
      if (
        this.allowEditPhoneNumberAfter != null &&
        Date.parse(this.allowEditPhoneNumberAfter) > new Date()
      ) {
        this.$swal({
          title: this.$i18n.t('profile.swal.phone.cannotChangeT'),
          text: `${this.$i18n.t('profile.swal.phone.cannotChangeM')} ${this.$date.format(
            this.allowEditPhoneNumberAfter,
            'dd MMMM yyyy',
          )}`,
          type: 'error',
          confirmButtonColor: '#3085d6',
          confirmButtonText: this.$i18n.t('general.ok'),
        });
      } else {
        this.$modal.show('modal-phone', {
          from: 'profile',
          dismissable: true,
        });
      }
    },
    handleContact() {
      let url = '';
      const contactNumber = '+' + this.$t('general.phone2');
      const text = 'Halo, saya butuh bantuan terkait akun saya.';
      url = `https://wa.me/${contactNumber}?text=${text}`;

      window.open(url, '_blank');
    },
  },
};
</script>
